import React from "react";
import Navbar from "../../../components/Navbar";

function DataAlumni() {
  return (
    <div>
      <Navbar name="Data Alumni" path="/" />
      <div>Data Alumni Content</div>
    </div>
  );
}

export default DataAlumni;
