import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import "react-quill/dist/quill.snow.css";
import { FiEdit, FiDownload } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { API_URL } from "config";

function Statuta() {
  const [statuta, setStatuta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/about/statuta_perguruan_tinggi`)
      .then((response) => {
        setStatuta(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/tentang-itba/statuta/edit-statuta/${id}/${slug}`;
  };

  const handleButtonDownload = (file) => {
    window.location.href = `${API_URL}/repository/${file.item_file}`;
  };

  return (
    <div>
      <Navbar name="Statuta" path="Statuta" />
      {isLoading ? (
        <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-row space-x-4 grow justify-end items-center">
                <button
                  onClick={() => handleButton(statuta.id, statuta.id_item)}
                  className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <FiEdit /> {"\u00A0"} Edit Statuta
                </button>
              </div>
              <div className="flex flex-col py-4">
                <div>
                  <div className="text-blue-600 text-sm mb-2">
                    Statuta Perguruan Tinggi
                  </div>
                  <button
                    onClick={() => handleButtonDownload(statuta)}
                    className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                  >
                    <FiDownload /> {"\u00A0"} Download File Statuta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Statuta;
