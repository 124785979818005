import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import "react-quill/dist/quill.snow.css";
import { FiEdit } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { API_URL } from "config";

function StrukturOrganisasi() {
  const [strukturOrganisasi, setStrukturOrganisasi] = useState([]);
  const [pimpinan, setPimpinan] = useState([]);
  const [senat, setSenat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleButton = (id, slug) => {
    window.location.href = `/tentang-itba/struktur-organisasi/edit-${slug}/${id}/${slug}`;
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/about/all/struktur_organisasi`)
      .then((response) => {
        setStrukturOrganisasi(
          response.data.find((obj) => obj.id_item === "struktur_organisasi")
        );
        setSenat(response.data.find((obj) => obj.id_item === "senat_akademik"));
        setPimpinan(
          response.data.find(
            (obj) => obj.id_item === "pimpinan_perguruan_tinggi"
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Navbar name="Struktur Organisasi" path="Struktur Organisasi" />
      {isLoading ? (
        <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-row space-x-4 grow justify-end items-center">
                <button
                  onClick={() =>
                    handleButton(
                      strukturOrganisasi.id,
                      strukturOrganisasi.id_item
                    )
                  }
                  className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <FiEdit /> {"\u00A0"} Edit Sturktur Organisasi
                </button>
              </div>
              <div className="flex flex-col py-4">
                <div className="mb-4">
                  <div className="text-blue-600 text-sm mb-2">
                    Preview Struktur Organisasi
                  </div>
                  <div>{strukturOrganisasi.item_preview}</div>
                </div>
                <div>
                  <div className="text-blue-600 text-sm mb-2">
                    Bagan Struktur Organisasi
                  </div>
                  <img
                    src={`${API_URL}/repository/${strukturOrganisasi.item_file}`}
                    alt="Thumb"
                    className="w-40 h-36 mx-4 bg-slate-100 object-cover rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-row space-x-4 grow justify-end items-center">
                <button
                  onClick={() => handleButton(pimpinan.id, pimpinan.id_item)}
                  className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <FiEdit /> {"\u00A0"} Edit Pimpinan Perguruan Tinggi
                </button>
              </div>
              <div className="flex flex-col py-4">
                <div className="mb-4">
                  <div className="text-blue-600 text-sm mb-2">
                    Preview Pimpinan Perguruan Tinggi
                  </div>
                  <div>{pimpinan.item_preview}</div>
                </div>
                <div>
                  <div className="text-blue-600 text-sm mb-2">
                    Tentang Pimpinan Perguruan Tinggi
                  </div>
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: pimpinan.item_content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-row space-x-4 grow justify-end items-center">
                <button
                  onClick={() => handleButton(senat.id, senat.id_item)}
                  className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <FiEdit /> {"\u00A0"} Edit Senat Akademik
                </button>
              </div>
              <div className="flex flex-col py-4">
                <div className="mb-4">
                  <div className="text-blue-600 text-sm mb-2">
                    Preview Senat Akademik
                  </div>
                  <div>{senat.item_preview}</div>
                </div>
                <div>
                  <div className="text-blue-600 text-sm mb-2">
                    Senat Akademik Lengkap
                  </div>
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: senat.item_content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StrukturOrganisasi;
