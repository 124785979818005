import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import Navbar from "components/Navbar";
import ListProdi from "./ListProdi";

function ProgramStudi() {
  const handleButton = () => {
    window.location.href = "/akademik/program-studi/create-prodi";
  };

  return (
    <div>
      <Navbar name="Program Studi" path="Program Studi" />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        <div className="grow space-y-1">
          <div className="flex flex-row space-x-4 grow justify-end items-center">
            <button
              onClick={() => handleButton()}
              className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
            >
              <AiFillPlusCircle /> {"\u00A0"} Tambah Prodi
            </button>
          </div>
          <ListProdi />
        </div>
      </div>
    </div>
  );
}

export default ProgramStudi;
