import React, { useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import axios from "axios";
import Navbar from "components/Navbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
    stripPastedStyles: true,
    pastePlainTex: true,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
};

function EditSenat() {
  // Get Data
  const { slug } = useParams();
  const getSenatUrl = `${API_URL}/about/${slug}`;

  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [previewItem, setpreviewItem] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    axios
      .get(getSenatUrl)
      .then((response) => {
        setpreviewItem(response.data.item_preview);
        setContent(response.data.item_content);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getSenatUrl]);

  const handleButton = () => {
    window.location.href = "/tentang-itba/struktur-organisasi";
  };

  const updateSenat = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .patch(
          `${API_URL}/about/${slug}`,
          {
            item_preview: previewItem,
            item_content: content,
            updatedAt: new Date(),
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/tentang-itba/struktur-organisasi";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Edit Senat Akademik"
        path="Struktur Organisasi / Edit Senat Akademik"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={updateSenat}>
              <div className="news-title-form relative my-6">
                <div className="text-blue-600 text-sm mb-2">Isi Preview</div>
                <textarea
                  className="resize-none p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={previewItem}
                  onChange={(e) => setpreviewItem(e.target.value)}
                />
              </div>
              <div
                className="about-sejarahlengkap-form editor my-4"
                style={{ height: "390px" }}
              >
                <div className="text-blue-600 text-sm mb-2">
                  Isi Sejarah Lengkap
                </div>
                <ReactQuill
                  style={{ height: "310px" }}
                  theme="snow"
                  value={content}
                  modules={modules}
                  onChange={setContent}
                  readOnly={false}
                />
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditSenat;
