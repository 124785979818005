import React, { useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import axios from "axios";
import { slugify } from "assets/js/slugify";
import Navbar from "components/Navbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
    stripPastedStyles: true,
    pastePlainTex: true,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
};

function EditBerita() {
  // Get Data
  const { id, slug } = useParams();
  const getNewsUrl = `${API_URL}/berita/${id}/${slug}`;

  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [title, setTitle] = useState("");
  const [thumb, setThumb] = useState("");
  const [oldThumb, setOldThumb] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    axios
      .get(getNewsUrl)
      .then((response) => {
        setTitle(response.data.news_title);
        setContent(response.data.news_content);
        setThumb(response.data.news_thumb);
        setOldThumb(response.data.news_thumb);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getNewsUrl]);

  const submitThumbnail = (e) => {
    const submitFileThumb = e.target.files[0];
    setThumb(submitFileThumb);
  };

  const handleButton = () => {
    window.location.href = "/tentang-itba/berita";
  };

  const updateNews = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .patch(
          `${API_URL}/berita/${id}`,
          {
            news_title: title,
            news_slug: slugify(title),
            news_thumb: thumb,
            news_author: "Admin",
            news_content: content,
            updatedAt: new Date(),
            old_thumb: oldThumb,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/tentang-itba/berita";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Edit Berita Kampus"
        path="Berita Kampus / Edit Berita Kampus"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={updateNews}>
              <div className="news-title-form relative my-6">
                <input
                  id="newsTitle"
                  name="newsTitle"
                  type="text"
                  required
                  value={title}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                  placeholder="Judul Berita"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <label
                  htmlFor="newsTitle"
                  className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                >
                  Judul Berita
                </label>
              </div>
              <div
                className="news-content-form editor my-4"
                style={{ height: "390px" }}
              >
                <div className="text-blue-600 text-sm mb-2">Isi Berita</div>
                <ReactQuill
                  style={{ height: "310px" }}
                  theme="snow"
                  value={content}
                  modules={modules}
                  onChange={setContent}
                  readOnly={false}
                />
              </div>
              <div className="news-thumb-form relative mb-6">
                <div className="text-blue-600 text-sm mb-4">
                  Upload Thumbnail
                </div>
                <input
                  id="uploadThum"
                  name="uploadThum"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={submitThumbnail}
                  className="hidden"
                />
                <label
                  htmlFor="uploadThum"
                  className="px-4 py-2 bg-blue-500 text-white border border-gray-300 rounded-md cursor-pointer hover:bg-blue-200 hover:border-blue-500 hover:text-gray-600 focus-within:bg-blue-200"
                >
                  {thumb.name ? thumb.name : thumb}
                </label>
                <p
                  className="mt-4 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  PNG, JPG or JPEG (MAX. 5MB).
                </p>
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditBerita;
