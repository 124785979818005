import {
  AiOutlineDashboard,
  AiOutlineHome,
  AiOutlineBook,
  AiOutlineRead,
  AiOutlineTeam,
} from "react-icons/ai";
import { VscCircleFilled } from "react-icons/vsc";
import Dashboard from "./pages/Dashboard";
import Berita from "./pages/Tentang/Berita";
import Legalitas from "./pages/Tentang/Legalitas";
import LogoKampus from "./pages/Tentang/Logo";
import Sejarah from "./pages/Tentang/Sejarah";
import Statuta from "./pages/Tentang/Statuta";
import StrukturOrganisasi from "./pages/Tentang/StrukturOrganisasi";
import VisiMisi from "./pages/Tentang/Visi&Misi";
import JurnalPublikasi from "./pages/Publikasi/JurnalPublikasi";
import ProgramStudi from "./pages/Akademik/ProgramStudi";
import InfoBeasiswa from "./pages/Akademik/InfoBeasiswa";
import AgendaAkademik from "./pages/Akademik/AgendaAkademik";
import DataAlumni from "./pages/TracerStudy/DataAlumni";
import InfoPengabdian from "./pages/Pengabdian/InfoPengabdian";

const routes = [
  {
    name: "Dashboard",
    icon: <AiOutlineDashboard />,
    slug: "dashboard",
    path: "/",
    component: <Dashboard />,
    child: [],
  },
  {
    name: "ITBA Al-Gazali",
    icon: <AiOutlineHome />,
    slug: "tentang-itba",
    path: "",
    component: "",
    child: [
      {
        name: "Berita",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/berita",
        component: <Berita />,
      },
      {
        name: "Sejarah",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/sejarah",
        component: <Sejarah />,
      },
      {
        name: "Visi & Misi",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/visi-misi",
        component: <VisiMisi />,
      },
      {
        name: "Struktur Organisasi",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/struktur-organisasi",
        component: <StrukturOrganisasi />,
      },
      {
        name: "Statuta",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/statuta",
        component: <Statuta />,
      },
      {
        name: "Legalitas",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/legalitas",
        component: <Legalitas />,
      },
      {
        name: "Logo",
        icon: <VscCircleFilled />,
        path: "/tentang-itba/logo",
        component: <LogoKampus />,
      },
    ],
  },
  {
    name: "Akademik",
    icon: <AiOutlineBook />,
    path: "",
    slug: "akademik",
    component: "",
    child: [
      {
        name: "Program Studi",
        icon: <VscCircleFilled />,
        path: "/akademik/program-studi",
        component: <ProgramStudi />,
      },
      {
        name: "Info Beasiswa",
        icon: <VscCircleFilled />,
        path: "/akademik/info-beasiswa",
        component: <InfoBeasiswa />,
      },
      {
        name: "Agenda Akademik",
        icon: <VscCircleFilled />,
        path: "/akademik/agenda-akademik",
        component: <AgendaAkademik />,
      },
    ],
  },
  {
    name: "Publikasi",
    icon: <AiOutlineRead />,
    path: "",
    slug: "publikasi",
    component: "",
    child: [
      {
        name: "Jurnal Publikasi",
        icon: <VscCircleFilled />,
        path: "/publikasi/jurnal-publikasi",
        component: <JurnalPublikasi />,
      },
    ],
  },
  {
    name: "Pengabdian",
    icon: <AiOutlineRead />,
    path: "",
    slug: "pengabdian",
    component: "",
    child: [
      {
        name: "Info Pengabdian",
        icon: <VscCircleFilled />,
        path: "/pengabdian/info-pengabdian",
        component: <InfoPengabdian />,
      },
    ],
  },
  {
    name: "Tracer Study",
    icon: <AiOutlineTeam />,
    path: "",
    slug: "tracer-study",
    component: "",
    child: [
      {
        name: "Data Alumni",
        icon: <VscCircleFilled />,
        path: "/tracer-study/data-alumni",
        component: <DataAlumni />,
      },
    ],
  },
];

export default routes;
