import React from "react";
import Navbar from "../../../components/Navbar";

function InfoBeasiswa() {
  return (
    <div>
      <Navbar name="Info Beasiswa" path="/" />
      <div>InfoBeasiswa Content</div>
    </div>
  );
}

export default InfoBeasiswa;
