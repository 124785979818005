import React, { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { slugify } from "assets/js/slugify";
import Navbar from "components/Navbar";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

function NewEvent() {
  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventContent, setEventContent] = useState("");

  const handleButton = () => {
    window.location.href = "/akademik/agenda-akademik";
  };

  const handleDateChange = (event) => {
    setEventDate(event.target.value);
  };

  const uploadProdi = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .post(
          `${API_URL}/event`,
          {
            event_title: eventTitle,
            event_id: slugify(eventTitle),
            event_date: eventDate,
            event_content: eventContent,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/akademik/agenda-akademik";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Buat Agenda Akademik"
        path="Agenda Akademik / Buat Agenda Akademik"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={uploadProdi}>
              <div className="event-title-form relative my-6">
                <input
                  id="eventTitle"
                  name="eventTitle"
                  type="text"
                  required
                  value={eventTitle}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                  placeholder="Nama Agenda"
                  onChange={(e) => setEventTitle(e.target.value)}
                />
                <label
                  htmlFor="eventTitle"
                  className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                >
                  Nama Agenda Akademik
                </label>
              </div>
              <div className="event-content-form relative my-6">
                <div className="text-blue-600 text-sm mb-2">
                  Detail Agenda Akademik
                </div>
                <textarea
                  className="resize-none p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={eventContent}
                  onChange={(e) => setEventContent(e.target.value)}
                />
              </div>
              <div className="max-w-sm mb-4">
                <label
                  htmlFor="dateInputAkreditasi"
                  className="block text-blue-600 mb-2"
                >
                  Tanggal Akreditasi:
                </label>
                <input
                  type="date"
                  id="dateInputAkreditasi"
                  value={eventDate ? eventDate : ""}
                  onChange={handleDateChange}
                  className="border border-gray-500 py-2 px-3 rounded-md w-full"
                />
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewEvent;
