import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { API_URL } from "config";

const ITEMS_PER_PAGE = 10;

function ListProdi() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/prodi`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleOnClick = (id, slug) => {
    window.location.href = `/akademik/program-studi/edit-prodi/${id}/${slug}`;
  };

  const handleOnDelete = (id) => {
    setIsLoading(true);
    axios
      .delete(`${API_URL}/prodi/${id}`)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        window.location.href = `/akademik/program-studi`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const start = (currentPage - 1) * ITEMS_PER_PAGE;
  const end = start + ITEMS_PER_PAGE;
  const currentItems = data.slice(start, end);

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  return (
    <div>
      {isLoading ? (
        <div className="text-center my-8 w-full">
          <ClipLoader color="#0075ff" loading={isLoading} />
        </div>
      ) : (
        <div className="flex flex-col py-4 grow justify-center items-center">
          {currentItems.map((item) => (
            <div key={item.id} className="my-1 w-full">
              <div className="bg-blue-500 rounded-xl flex justify-between text-center shadow-md">
                <div className="bg-white rounded-xl w-full border border-white px-2 py-4 flex text-center">
                  <div className="flex flex-col justify-center text-start">
                    <p className="text-lg ml-3 text-gray-900">
                      {`${item.prodi_name} (${item.jenjang_prodi})`}
                    </p>
                    <p className="text-xs ml-3 text-gray-400">
                      {item.peringkat
                        ? `Akreditasi ${item.peringkat}`
                        : "Belum Terakreditasi"}
                    </p>
                  </div>
                </div>
                <div className="bg-blue-500 rounded-xl flex justify-end items-center text-center mx-2">
                  <FiEdit
                    className="text-2xl mx-2 text-white cursor-pointer"
                    onClick={() => handleOnClick(item.id, item.prodi_id)}
                  />
                  <FiTrash2
                    className="text-2xl mx-2 text-white cursor-pointer"
                    onClick={() => handleOnDelete(item.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-end">
        <div>
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="h-12 rounded-l-lg border-2 border-r-0 border-blue-500 px-4 hover:bg-blue-500 hover:text-white"
          >
            Prev
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className="h-12 w-12 border-2 border-r-0 border-blue-500 px-4 hover:bg-blue-500 hover:text-white"
            >
              {i + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="h-12 rounded-r-lg border-2  border-blue-500 px-4 hover:bg-blue-500 hover:text-white"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default ListProdi;
