import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import "react-quill/dist/quill.snow.css";
import { FiEdit } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { API_URL } from "config";

function LogoKampus() {
  const [logoHitamPutih, setLogoHitamPutih] = useState([]);
  const [logoWarna, setLogoWarna] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/about/all/logo`)
      .then((response) => {
        setLogoWarna(
          response.data.find((obj) => obj.id_item === "logo_berwarna_kampus")
        );
        setLogoHitamPutih(
          response.data.find((obj) => obj.id_item === "logo_hitamputih_kampus")
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/tentang-itba/logo/edit-${slug}/${id}/${slug}`;
  };

  return (
    <div>
      <Navbar name="Logo Kampus" path="Logo Kampus" />
      {isLoading ? (
        <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
              <div className="grow space-y-1">
                <div className="flex flex-row space-x-4 grow justify-end items-center">
                  <button
                    onClick={() =>
                      handleButton(logoHitamPutih.id, logoHitamPutih.id_item)
                    }
                    className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                  >
                    <FiEdit /> {"\u00A0"} Edit Logo Hitam Putih
                  </button>
                </div>
                <div className="flex flex-col py-4">
                  <div>
                    <div className="text-blue-600 text-sm mb-2">
                      Logo Hitam Putih
                    </div>
                    <img
                      src={`${API_URL}/repository/${logoHitamPutih.item_file}`}
                      alt="Thumb"
                      className="w-40 h-36 mx-4 object-contain rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
              <div className="grow space-y-1">
                <div className="flex flex-row space-x-4 grow justify-end items-center">
                  <button
                    onClick={() =>
                      handleButton(logoWarna.id, logoWarna.id_item)
                    }
                    className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                  >
                    <FiEdit /> {"\u00A0"} Edit Logo Berwarna
                  </button>
                </div>
                <div className="flex flex-col py-4">
                  <div>
                    <div className="text-blue-600 text-sm mb-2">
                      Logo Berwarna
                    </div>
                    <img
                      src={`${API_URL}/repository/${logoWarna.item_file}`}
                      alt="Thumb"
                      className="w-40 h-36 mx-4 object-contain rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LogoKampus;
