import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import "react-quill/dist/quill.snow.css";
import { FiEdit, FiDownload } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { dateFormatV2 } from "assets/js/dateFormat";
import { API_URL } from "config";

function Legalitas() {
  const [legalitas, setLegalitas] = useState([]);
  const [legalitasProdi, setLegalitasProdi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const requestKampus = axios.get(`${API_URL}/about/legalitas_kampus`);
      const requestProdi = axios.get(`${API_URL}/prodi`);

      axios
        .all([requestKampus, requestProdi])
        .then(
          axios.spread((...responses) => {
            const responseKampus = responses[0];
            const responseProdi = responses[1];

            setLegalitas(responseKampus.data);
            setLegalitasProdi(responseProdi.data);
            setIsLoading(false);
          })
        )
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/tentang-itba/legalitas/edit-legalitas-kampus/${id}/${slug}`;
  };

  const handleButtonDownload = (file) => {
    window.location.href = `${API_URL}/repository/${file.item_file}`;
  };

  const handleOnClick = (id, slug) => {
    window.location.href = `/tentang-itba/legalitas/edit-legalitas-prodi/${id}/${slug}`;
  };

  return (
    <div>
      <Navbar name="Legalitas" path="Legalitas" />
      {isLoading ? (
        <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-row space-x-4 grow justify-end items-center">
                <button
                  onClick={() => handleButton(legalitas.id, legalitas.id_item)}
                  className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <FiEdit /> {"\u00A0"} Edit Legalitas Kampus
                </button>
              </div>
              <div className="flex flex-col py-4">
                <div>
                  <div className="text-blue-600 text-sm mb-2">
                    Legalitas Kampus
                  </div>
                  <button
                    onClick={() => handleButtonDownload(legalitas)}
                    className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                  >
                    <FiDownload /> {"\u00A0"} Download File Legalitas Kampus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
            <div className="grow space-y-1">
              <div className="flex flex-col py-4">
                <div>
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nama Prodi
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Program
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nomor SK. Akreditasi
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Tahun
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Daluwarsa Akreditasi
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Peringkat
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {legalitasProdi.map((obj, index) => (
                        <tr key={obj.id}>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.prodi_name}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.jenjang_prodi}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.no_sk_akredit}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.tahun_akredit
                              ? dateFormatV2(obj.tahun_akredit)
                              : ""}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.expired_akredit
                              ? dateFormatV2(obj.expired_akredit)
                              : ""}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            {obj.peringkat}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap">
                            <FiEdit
                              className="text-2xl mx-2 text-blue-500 cursor-pointer"
                              onClick={() =>
                                handleOnClick(obj.id, obj.prodi_id)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Legalitas;
