import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/itba.png";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import routes from "../../router";

function Sidebar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuActive, setMenuActive] = useState("");

  const navigate = useNavigate();
  const navigateButton = (path) => {
    if (path !== "") {
      navigate(path);
    }
  };

  const handleMenuClick = (child, path) => {
    if (path !== "") {
      navigate(path);
    }

    if (child === menuActive) {
      setIsMenuOpen((current) => !current);
    } else {
      setIsMenuOpen(true);
    }

    setMenuActive(child);
  };

  useEffect(() => {
    if (!isMenuOpen && menuActive === "") {
      let path = window.location.pathname;
      if (path === "/") {
        setIsMenuOpen(true);
        setMenuActive("dashboard");
      } else {
        let menuPath = path.substring(1).split("/");
        setIsMenuOpen(true);
        setMenuActive(menuPath[0]);
      }
    }
  }, [isMenuOpen, menuActive]);

  return (
    <div className="h-screen border-r border-gray-200 w-64 px-9 space-y-24">
      <div className="flex flex-row items-center pt-8">
        <img src={Logo} alt="Logo" className="w-9 h-9 p-0.5" />
        <div>ITBA Al-Gazali Barru</div>
      </div>
      <div className="space-y-6">
        <div>
          <div className="mb-4 text-indigo-700">Menu</div>
          <ul className="space-y-7">
            {routes.map((val, index) => {
              return (
                <li
                  key={index}
                  className={
                    val.slug === menuActive
                      ? "text-gray-900 cursor-pointer"
                      : "text-gray-500 cursor-pointer"
                  }
                >
                  <div
                    className="mb-7 flex flex-row items-center"
                    onClick={() => handleMenuClick(val.slug, val.path)}
                  >
                    <div className="mr-4">{val.icon}</div>
                    <div className="flex-grow">{val.name}</div>
                    {val.child.length > 0 ? (
                      isMenuOpen && val.slug === menuActive ? (
                        <RiArrowDropDownLine />
                      ) : (
                        <RiArrowDropRightLine />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  {isMenuOpen && val.slug === menuActive ? (
                    <ul className="ml-7">
                      {val.child.map((menuChild, index) => (
                        <li
                          key={index}
                          className="mb-7 flex flex-row items-center"
                          onClick={() => navigateButton(menuChild.path)}
                        >
                          <div className="mr-4">{menuChild.icon}</div>
                          <div>{menuChild.name}</div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
