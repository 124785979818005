import React, { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { slugify } from "assets/js/slugify";
import Navbar from "components/Navbar";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

const ALLOWED_FILE_TYPE = ".jpg,.jpeg,.png";

function NewPublikasi() {
  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [jurnalName, setJurnalName] = useState("");
  const [jurnalThumb, setJurnalThumb] = useState("");
  const [jurnalUrl, setJurnalUrl] = useState("");
  const [jurnalDesc, setJurnalDesc] = useState("");

  const submitThumbnail = (e) => {
    const submitFileThumb = e.target.files[0];
    setJurnalThumb(submitFileThumb);
  };

  const handleButton = () => {
    window.location.href = "/publikasi/jurnal-publikasi";
  };

  const uploadPublikasi = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .post(
          `${API_URL}/publikasi`,
          {
            jurnal_id: slugify(jurnalName),
            jurnal_name: jurnalName,
            jurnal_url: jurnalUrl,
            jurnal_desc: jurnalDesc,
            jurnal_thumb: jurnalThumb,
            allowedFiletype: ALLOWED_FILE_TYPE,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/publikasi/jurnal-publikasi";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Tambah Jurnal Publikasi"
        path="Jurnal Publikasi / Tambah Jurnal Publikasi"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={uploadPublikasi}>
              <div className="news-title-form relative my-6">
                <input
                  id="jurnalName"
                  name="jurnalName"
                  type="text"
                  required
                  value={jurnalName}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                  placeholder="Judul Berita"
                  onChange={(e) => setJurnalName(e.target.value)}
                />
                <label
                  htmlFor="jurnalName"
                  className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                >
                  Nama Jurnal Publikasi
                </label>
              </div>
              <div className="news-title-form relative my-6">
                <input
                  id="jurnalUrl"
                  name="jurnalUrl"
                  type="text"
                  required
                  value={jurnalUrl}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                  placeholder="Judul Berita"
                  onChange={(e) => setJurnalUrl(e.target.value)}
                />
                <label
                  htmlFor="jurnalUrl"
                  className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                >
                  URL Jurnal Publikasi
                </label>
              </div>
              <div className="event-content-form relative my-6">
                <div className="text-blue-600 text-sm mb-2">
                  Deskripsi Jurnal Publikasi
                </div>
                <textarea
                  className="resize-none p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={jurnalDesc}
                  onChange={(e) => setJurnalDesc(e.target.value)}
                />
              </div>
              <div className="news-thumb-form relative mb-6">
                <div className="text-blue-600 text-sm mb-4">
                  Upload Thumbnail
                </div>
                <input
                  id="uploadThum"
                  name="uploadThum"
                  type="file"
                  accept={ALLOWED_FILE_TYPE}
                  onChange={submitThumbnail}
                  className="hidden"
                />
                <label
                  htmlFor="uploadThum"
                  className="px-4 py-2 bg-blue-500 text-white border border-gray-300 rounded-md cursor-pointer hover:bg-blue-200 hover:border-blue-500 hover:text-gray-600 focus-within:bg-blue-200"
                >
                  {jurnalThumb ? jurnalThumb.name : "No file selected"}
                </label>
                <p
                  className="mt-4 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  PNG, JPG or JPEG (MAX. 5MB).
                </p>
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewPublikasi;
