import React from "react";
import Navbar from "../../components/Navbar";

function Dashboard() {
  return (
    <div>
      <Navbar name="Dashboard" path="Dashboard" />
      <div>Dashboard Content</div>
    </div>
  );
}

export default Dashboard;
