import Sidebar from "./components/Sidebar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./router";
import EditBerita from "pages/Tentang/Berita/EditBerita";
import NewBerita from "pages/Tentang/Berita/NewBerita";
import EditSejarah from "pages/Tentang/Sejarah/EditSejarah";
import EditVisiMisi from "pages/Tentang/Visi&Misi/EditVisi&Misi";
import EditPimpinan from "pages/Tentang/StrukturOrganisasi/EditPimpinan";
import EditSenat from "pages/Tentang/StrukturOrganisasi/EditSenat";
import EditStruktur from "pages/Tentang/StrukturOrganisasi/EditStruktur";
import EditStatuta from "pages/Tentang/Statuta/EditStatuta";
import EditLegalitasKampus from "pages/Tentang/Legalitas/EditLegalitasKampus";
import EditLogo from "pages/Tentang/Logo/EditLogo";
import EditProdi from "pages/Akademik/ProgramStudi/EditProdi";
import NewProdi from "pages/Akademik/ProgramStudi/NewProdi";
import EditLegalitasProdi from "pages/Tentang/Legalitas/EditLegalitasProdi";
import NewEvent from "pages/Akademik/AgendaAkademik/NewEvent";
import EditEvent from "pages/Akademik/AgendaAkademik/EditEvent";
import NewPublikasi from "pages/Publikasi/JurnalPublikasi/NewPublikasi";
import EditPublikasi from "pages/Publikasi/JurnalPublikasi/EditPublikasi";
import NewPengabdian from "pages/Pengabdian/InfoPengabdian/NewPengabdian";
import EditPengabdian from "pages/Pengabdian/InfoPengabdian/EditPengabdian";

function App() {
  return (
    <BrowserRouter>
      <div className="w-full min-h-screen bg-white flex flex-row">
        <Sidebar />
        <section className="flex-1 m-4">
          <div>
            <Routes>
              {routes.map((val, index) => {
                return val.path !== "" ? (
                  <Route
                    key={index}
                    path={val.path}
                    element={val.component}
                  ></Route>
                ) : (
                  val.child.map((menuChild, index) => {
                    return (
                      <Route
                        key={index}
                        path={menuChild.path}
                        element={menuChild.component}
                      ></Route>
                    );
                  })
                );
              })}
              <Route
                path="tentang-itba/berita/edit-berita/:id/:slug"
                element={<EditBerita />}
              />
              <Route
                path="tentang-itba/berita/create-berita"
                element={<NewBerita />}
              />

              <Route
                path="tentang-itba/sejarah/edit-sejarah/:id/:slug"
                element={<EditSejarah />}
              />

              <Route
                path="tentang-itba/visi-misi/edit-visi-misi/:id/:slug"
                element={<EditVisiMisi />}
              />

              <Route
                path="tentang-itba/struktur-organisasi/edit-struktur_organisasi/:id/:slug"
                element={<EditStruktur />}
              />
              <Route
                path="tentang-itba/struktur-organisasi/edit-pimpinan_perguruan_tinggi/:id/:slug"
                element={<EditPimpinan />}
              />
              <Route
                path="tentang-itba/struktur-organisasi/edit-senat_akademik/:id/:slug"
                element={<EditSenat />}
              />

              <Route
                path="tentang-itba/statuta/edit-statuta/:id/:slug"
                element={<EditStatuta />}
              />

              <Route
                path="tentang-itba/legalitas/edit-legalitas-kampus/:id/:slug"
                element={<EditLegalitasKampus />}
              />
              <Route
                path="tentang-itba/legalitas/edit-legalitas-prodi/:id/:slug"
                element={<EditLegalitasProdi />}
              />

              <Route
                path="tentang-itba/logo/edit-logo_hitamputih_kampus/:id/:slug"
                element={<EditLogo />}
              />
              <Route
                path="tentang-itba/logo/edit-logo_berwarna_kampus/:id/:slug"
                element={<EditLogo />}
              />

              <Route
                path="akademik/program-studi/edit-prodi/:id/:slug"
                element={<EditProdi />}
              />
              <Route
                path="akademik/program-studi/create-prodi"
                element={<NewProdi />}
              />

              <Route
                path="akademik/event/edit-event/:id"
                element={<EditEvent />}
              />
              <Route
                path="akademik/event/create-event"
                element={<NewEvent />}
              />

              <Route
                path="publikasi/jurnal-publikasi/edit-publikasi/:id/:slug"
                element={<EditPublikasi />}
              />
              <Route
                path="publikasi/jurnal-publikasi/create-publikasi"
                element={<NewPublikasi />}
              />

              <Route
                path="pengabdian/info-pengabdian/edit-pengabdian/:id/:slug"
                element={<EditPengabdian />}
              />
              <Route
                path="pengabdian/info-pengabdian/create-pengabdian"
                element={<NewPengabdian />}
              />
            </Routes>
          </div>
        </section>
      </div>
    </BrowserRouter>
  );
}

export default App;
