import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import Navbar from "components/Navbar";
import { FiEdit } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { API_URL } from "config";

function VisiMisi() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleButton = (id, slug) => {
    window.location.href = `/tentang-itba/visi-misi/edit-visi-misi/${id}/${slug}`;
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/about/visi_misi`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Navbar name="Visi & Misi" path="Visi & Misi" />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton(data.id, data.id_item)}
                className="flex items-center px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
              >
                <FiEdit /> {"\u00A0"} Edit Visi & Misi
              </button>
            </div>
            <div className="flex flex-col py-4">
              <div>
                <div className="text-blue-600 text-sm mb-2">Visi & Misi</div>
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data.item_content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VisiMisi;
