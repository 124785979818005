import React from "react";
import {
  AiOutlineUser,
  AiOutlineDashboard,
  AiOutlineSetting,
  AiOutlineBell,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Navbar = ({ name, path }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row p-2 mb-4 border border-gray-200 rounded-xl">
      <div className="grow space-y-1">
        <div className="flex flex-row items-center">
          <AiOutlineDashboard onClick={() => navigate("/")} />
          <div className="font-light text-sm">
            {" "}
            {"\u00A0"}/ {path}
          </div>
        </div>
        <div className="font-medium">{name}</div>
      </div>
      <div className="flex flex-row space-x-4 text-2xl grow justify-end items-center">
        <AiOutlineUser />
        <AiOutlineSetting />
        <AiOutlineBell />
      </div>
    </div>
  );
};

export default Navbar;
