import React, { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { slugify } from "assets/js/slugify";
import Navbar from "components/Navbar";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

function NewProdi() {
  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [prodiName, setProdiName] = useState("");
  const [jenjangProdi, setJenjangProdi] = useState("D4");

  const handleButton = () => {
    window.location.href = "/akademik/program-studi";
  };

  const handleChange = (event) => {
    setJenjangProdi(event.target.value);
  };

  const uploadProdi = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .post(
          `${API_URL}/prodi`,
          {
            prodi_id: slugify(prodiName),
            prodi_name: prodiName,
            jenjang_prodi: jenjangProdi,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/akademik/program-studi";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Buat Berita Kampus"
        path="Berita Kampus / Buat Berita Kampus"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={uploadProdi}>
              <div className="news-title-form relative my-6">
                <input
                  id="prodiName"
                  name="prodiName"
                  type="text"
                  required
                  value={prodiName}
                  className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                  placeholder="Nama Prodi"
                  onChange={(e) => setProdiName(e.target.value)}
                />
                <label
                  htmlFor="newsTitle"
                  className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                >
                  Nama Prodi
                </label>
              </div>
              <div className="inline-block relative w-full mb-4">
                <div className="text-blue-600 text-sm mb-2">Jenjang Prodi</div>
                <select
                  className="appearance-none border bg-white px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline w-full"
                  onChange={handleChange}
                  value={jenjangProdi}
                >
                  <option value={"D4"}>D4</option>
                  <option value={"S1"}>S1</option>
                  <option value={"S2"}>S2</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M14.707 7.293a1 1 0 0 0-1.414 0L10 10.586 6.707 7.293a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0 0-1.414z" />
                  </svg>
                </div>
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewProdi;
