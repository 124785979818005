import React, { useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import Navbar from "components/Navbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { API_URL } from "config";

const ALLOWED_FILE_TYPE = ".pdf";

function EditLegalitasProdi() {
  // Get Data
  const { id, slug } = useParams();
  const getLegalitasUrl = `${API_URL}/prodi/${id}/${slug}`;

  const [isLoading, setLoading] = useState(false);

  // Data Post
  const [skAkreditasi, setSkAkreditasi] = useState("");
  const [peringkat, setPeringkat] = useState("");
  const [selectedDateAkreditasi, setSelectedDateAkreditasi] = useState("");
  const [selectedDateAkreditasiExp, setSelectedDateAkreditasiExp] =
    useState("");
  const [filetype, setFiletype] = useState("");
  const [itemFile, setItemFile] = useState("");
  const [oldItem, setOldItem] = useState("");

  useEffect(() => {
    axios
      .get(getLegalitasUrl)
      .then((response) => {
        setSkAkreditasi(response.data.no_sk_akredit);
        setSelectedDateAkreditasi(response.data.tahun_akredit);
        setSelectedDateAkreditasiExp(response.data.expired_akredit);
        setFiletype(response.data.filetype);
        setItemFile(response.data.file_akreditasi);
        setOldItem(response.data.file_akreditasi);
        setPeringkat(response.data.peringkat);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getLegalitasUrl]);

  const submitFile = (e) => {
    const submitFileUpload = e.target.files[0];
    setItemFile(submitFileUpload);
  };

  const handleButton = () => {
    window.location.href = "/tentang-itba/legalitas";
  };

  const handleDateChange = (event) => {
    setSelectedDateAkreditasi(event.target.value);
  };

  const handleDateChangeExp = (event) => {
    setSelectedDateAkreditasiExp(event.target.value);
  };

  const updateLegalitasProdi = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log(selectedDateAkreditasi);
      console.log(selectedDateAkreditasiExp);
      await axios
        .patch(
          `${API_URL}/prodi/${id}`,
          {
            no_sk_akredit: skAkreditasi,
            tahun_akredit: selectedDateAkreditasi,
            expired_akredit: selectedDateAkreditasiExp,
            old_item: oldItem,
            allowedFiletype: ALLOWED_FILE_TYPE,
            filetype: filetype,
            item_file: itemFile,
            peringkat: peringkat.toUpperCase(),
            updatedAt: new Date(),
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data) {
              window.location.href = "/tentang-itba/legalitas";
            }
          }
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar
        name="Edit Legalitas Prodi"
        path="Legalitas / Edit Legalitas Prodi"
      />
      <div className="flex flex-row p-8 mb-4 border border-gray-200 rounded-xl">
        {isLoading ? (
          <div className="text-center my-8 w-full">
            <ClipLoader color="#0075ff" loading={isLoading} />
          </div>
        ) : (
          <div className="grow space-y-1">
            <div className="flex flex-row space-x-4 grow justify-end items-center">
              <button
                onClick={() => handleButton()}
                className="flex items-center px-2 py-2 bg-red-500 text-sm text-white rounded-md justify-center"
              >
                <AiFillCloseCircle /> {"\u00A0"} Batal
              </button>
            </div>
            <form className="mt-12" onSubmit={updateLegalitasProdi}>
              <div className="legalitas-prodi-form relative mb-6">
                <div className="legalitas-title-form relative my-6">
                  <input
                    id="skAkreditasi"
                    name="skAkreditasi"
                    type="text"
                    required
                    value={skAkreditasi ? skAkreditasi : ""}
                    className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                    placeholder="Judul Berita"
                    onChange={(e) => setSkAkreditasi(e.target.value)}
                  />
                  <label
                    htmlFor="peringkatAkreditasi"
                    className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                  >
                    Akreditasi Kampus
                  </label>
                </div>
                <div className="peringkat-form relative my-6">
                  <input
                    id="peringkatAkreditasi"
                    name="peringkatAkreditasi"
                    type="text"
                    required
                    value={peringkat ? peringkat : ""}
                    className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600"
                    placeholder="Akreditasi Kampus"
                    onChange={(e) => setPeringkat(e.target.value)}
                  />
                  <label
                    htmlFor="skAkreditasi"
                    className="absolute left-0 -top-3.5 text-blue-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2"
                  >
                    No SK Akreditasi
                  </label>
                </div>
                <div className="max-w-sm mb-4">
                  <label
                    htmlFor="dateInputAkreditasi"
                    className="block text-blue-600 mb-2"
                  >
                    Tanggal Akreditasi:
                  </label>
                  <input
                    type="date"
                    id="dateInputAkreditasi"
                    value={selectedDateAkreditasi ? selectedDateAkreditasi : ""}
                    onChange={handleDateChange}
                    className="border border-gray-500 py-2 px-3 rounded-md w-full"
                  />
                </div>
                <div className="max-w-sm mb-4">
                  <label
                    htmlFor="dateInputAkreditasiExpired"
                    className="block text-blue-600 mb-2"
                  >
                    Tanggal Akreditasi Daluwarsa:
                  </label>
                  <input
                    type="date"
                    id="dateInputAkreditasiExpired"
                    value={
                      selectedDateAkreditasiExp ? selectedDateAkreditasiExp : ""
                    }
                    onChange={handleDateChangeExp}
                    className="border border-gray-500 py-2 px-3 rounded-md w-full"
                  />
                </div>
                <div className="text-blue-600 text-sm mb-4">Upload File</div>
                <input
                  id="uploadFile"
                  name="uploadFile"
                  type="file"
                  {...(itemFile ? "" : { required: true })}
                  accept={ALLOWED_FILE_TYPE}
                  onChange={submitFile}
                  className="hidden"
                />
                <label
                  htmlFor="uploadFile"
                  className="px-4 py-2 bg-blue-500 text-white border border-gray-300 rounded-md cursor-pointer hover:bg-blue-200 hover:border-blue-500 hover:text-gray-600 focus-within:bg-blue-200"
                >
                  {itemFile
                    ? itemFile.name
                      ? itemFile.name
                      : itemFile
                    : "File Not Uploaded"}
                </label>
                <p
                  className="mt-4 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  PDF (MAX. 5MB).
                </p>
              </div>
              <div className="flex flex-row space-x-4 grow justify-start items-center">
                <button
                  type="submit"
                  className="flex items-center my-2 px-2 py-2 bg-blue-500 text-sm text-white rounded-md justify-center"
                >
                  <AiFillCheckCircle />
                  {"\u00A0"}
                  Simapan
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditLegalitasProdi;
